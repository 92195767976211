<script>
  export let event;
  let root;
	let contacts;
	let vacancies;

  const openLink = (linkAddress) => {
    if (linkAddress) window.open(linkAddress);
  };
  
	let isContactBoxDisplayed = false;
	const toggleContactBoxDisplayed = () => {
		isContactBoxDisplayed = !isContactBoxDisplayed;
	};
	
	let isVacancyBoxDisplayed = false;
	const toggleVacancyBoxDisplayed = () => {
		isVacancyBoxDisplayed = !isVacancyBoxDisplayed;
	};

  const prettyfyDGA = (waRank, dga) => {
    const parts = dga.split("-")
    const d = parts[0];
    const g = parts[1];
    const a = parts[2];
    let discipline = d;
    switch (d) {
      case "hj":
      discipline = "High Jump"
      break;
      case "lj":
      discipline = "Long Jump"
      break;
      case "tj":
      discipline = "Triple Jump"
      break;
      case "pv":
      discipline = "Pole Vault"
      break;
      case "sp":
      discipline = "Shot Put"
      break;
      case "ht":
      discipline = "Hammer Throw"
      break;
      case "dt":
      discipline = "Discus Throw"
      break;
      case "jt":
      discipline = "Javelin Throw"
      break;
    }
    const pretty = `${discipline} (${g.toUpperCase()} / ${a.toUpperCase()} / ${waRank.toUpperCase()})`
    return pretty
  }

  $: root = event.properties.links;
	$: contacts = event.properties.contacts;
	$: vacancies = event.properties.program.vacancies;
</script>

<div class="frame">
  <div class="section">
    <div class="section-items">
      <div
        class="item {root.website ? 'active' : ''}"
        on:click={() => openLink(root.website)}
      >
        <img
          class="item-icon"
          src="icons/website{root.website ? '--active' : ''}.png"
        />
        <div class="item-description">Website</div>
      </div>
      <div
        class="item {root.livestream ? 'active' : ''}"
        on:click={() => openLink(root.livestream)}
      >
        <img
          class="item-icon"
          src="icons/video{root.livestream ? '--active' : ''}.png"
        />
        <div class="item-description">Watch</div>
      </div>
      <div
        class="item active"
        on:click={() =>
          window.open(`event/${event.properties.slug || event.properties.id}`)}
      >
        <img class="item-icon" src="icons/share.png" />
        <div class="item-description--active'">Share</div>
      </div>  
    </div>
  </div>
  <div class="section">
    <div class="section-items">
      <div
        class="item {root.invitation ? 'active' : ''}"
        on:click={() => openLink(root.invitation)}
      >
        <img
          class="item-icon"
          src="icons/info{root.invitation ? '--active' : ''}.png"
        />
        <div class="item-description">Invitation</div>
      </div>
      <div
        class="item {root.startlist ? 'active' : ''}"
        on:click={() => openLink(root.startlist)}
      >
        <img
          class="item-icon"
          src="icons/list{root.startlist ? '--active' : ''}.png"
        />
        <div class="item-description">Start List</div>
      </div>
      <div
        class="item {root.results ? 'active' : ''}"
        on:click={() => openLink(root.results)}
      >
        <img
          class="item-icon"
          src="icons/stopwatch{root.results ? '--active' : ''}.png"
        />
        <div class="item-description">Results</div>
      </div>
    </div>
  </div>
  <div class="section">
   <div class="section-items2">
     <div
				class="item {contacts ? 'active' : ''}"
				on:click={toggleVacancyBoxDisplayed}
				style="background-color: #3498db; color: white"
			>
				<img class="item-icon" src="icons/hiring{vacancies && Object.keys(vacancies).length > 0 ? '--active' : ''}.png" />
				<div class="item-description">Vacancies</div>
			</div>
		      <div
				class="item {contacts ? 'active' : ''}"
				on:click={toggleContactBoxDisplayed}
				style="background-color: #3498db; color: white"
			>
				<img class="item-icon" src="icons/contacts{contacts && contacts.length > 0 ? '--active' : ''}.png" />
				<div class="item-description">Contacts</div>
			</div>
    </div>
  </div>
  {#if isContactBoxDisplayed}
		<div class="contact-box">
			<h4 style="font-size:18px;">Organizer Contacts</h4>
			{#each event.properties.contacts as contact}
				<p style="text-align: left; margin-top: 5px; font-size: 16px;">
					<img
						class="item-icon"
						src="icons/{contact.type === 'email' ? 'email' : 'phone'}.png"
						style="height: 14px;"
					/>
					<strong> {contact.about}:</strong>
					{contact.value}
				</p>
			{/each}
			<button on:click={toggleContactBoxDisplayed}>Close</button>
		</div>
	{/if}	
		
	{#if isVacancyBoxDisplayed} 
		<div class="contact-box">
			<h4 style="font-size:18px;">Vacancies {vacancies && Object.entries(vacancies).length > 0 ? " (*)" : ""}</h4>
			{#if vacancies && Object.entries(vacancies).length > 0}
			<table style="font-size:16px; text-align: center;">			
  			<tr>
  			  <th style="min-width: 200px;">Competition</th>
  			  <th style="width: 50px;">Spots</th>
  			  <th style="min-width: 60px;">Level</th>
  			</tr>
  			{#each Object.entries(vacancies) as [waRank, vacancy]}
          {#each Object.entries(vacancy) as [dga, vacancyDetails]}
           <tr>
            <td>{prettyfyDGA(waRank, dga)}</td>
            <td>{vacancyDetails.vacantLanes}</td>
            <td>{vacancyDetails.entryResult || 'N/A'}</td>
            </tr>
    {/each}
			{/each}
			</table>
			<br/>
			<p style="text-align: center;">(*) Please contact the organizers for more details</p>
			{:else}
<p style="text-align: center;">No vacancies announced</p>
			{/if}
			<button on:click={toggleVacancyBoxDisplayed}>Close</button>
		</div>
	{/if}

</div>

<style>
  .frame {
		position: relative;
    flex: 1;
    margin: 0 5px 0;
    display: flex;
    flex-direction: column;
    /* border: 1px solid green; */
  }
  
	.frame .contact-box {
		position: absolute;
		text-align: left;
		top: 50px;
		right: 0px;
    min-width: 300px;
		z-index: 1000;
		background-color: white;
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 15px;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	}
	.frame .contact-box h4 {
		text-align: center;
		margin: 0;
		margin-bottom: 12px;
	}
	
	.frame .contact-box p {
	  white-space: normal; /* Allows wrapping */
    word-break: normal; /* Prevents breaking inside words */
    text-align: left;
    text-indent: -20px; /* Negative indent for the first line */
    padding-left: 20px; /* Add padding to offset the negative indent */
    font-size: 14px;
	}

	.frame .contact-box button {
		position: absolute;
		right: 10px;
		top: 5px;
		background-color: #e91b5d;
		border: none;
		padding: 10px 15px;
		border-radius: 3px;
		color: white;
		cursor: pointer;
		margin-top: 3px;
	}
  .frame .section {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid navy; */
  }
  .frame .section .section-title {
    text-align: center;
    margin-bottom: 10px;
    padding: 8px 10px;
    background-color: lightgray;
    font-size: 16px;
    /* border: 1px solid blue; */
  }
  .frame .section .section-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    justify-items: center;
    /* display: flex;
    justify-content: space-evenly; */
    /* border: 1px solid brown; */
  }
  .frame .section .section-items .item {
    flex: 1 1 auto;
    height: 39px;
    width: 100%;
    max-width: 160px;
    overflow: hidden;
    margin: 10px 5px;
    padding: 5px 5px;
    border-radius: 5px;
    background-color: var(--color-foreg-text);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* border: 1px solid orange; */
  }
  .frame .section .section-items .item .item-icon {
    height: 25px;
    width: 25px;
    margin: 2px 5px;
    object-fit: contain;
    /* border: 1px solid black; */
  }
  .frame .section .section-items .item .item-description {
    display: table-cell;
    vertical-align: center;
    margin: 2px 5px;
    font-size: 16px;
    white-space: nowrap;
    /* border: 1px solid slateblue; */
  }
  .frame .section .section-items .active {
    cursor: pointer;
    color: #f58c20;
  }
  .frame .section .section-items .active:hover {
    background-color: var(--color-foreg-shadow);
  }
	.frame .section .section-items2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 10px;
		grid-row-gap: 0px;
		justify-items: center;
		/* display: flex;
	  justify-content: space-evenly; */
		/* border: 1px solid brown; */
	}
	.frame .section .section-items2 .item {
		flex: 1 1 auto;
		height: 39px;
		width: 100%;
		/* max-width: 160px; */
		overflow: hidden;
		margin: 10px 5px;
		padding: 5px 5px;
		border-radius: 5px;
		background-color: #3498db;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		/* border: 1px solid orange; */
	}

	.frame .section .section-items2 .item .item-icon {
		height: 25px;
		width: 25px;
		margin: 2px 5px;
		object-fit: contain;
		/* border: 1px solid black; */
	}
	.frame .section .section-items2 .item .item-description {
		display: table-cell;
		vertical-align: center;
		margin: 2px 5px;
		font-size: 16px;
		white-space: nowrap;
		/* border: 1px solid slateblue; */
	}
	.frame .section .section-items2 .active {
		cursor: pointer;
		color: #f58c20;
	}
	.frame .section .section-items2 .active:hover {
		background-color: var(--color-foreg-shadow);
	}
  a {
    text-decoration: none;
  }
</style>
