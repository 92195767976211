<script>
  import { createEventDispatcher, onMount } from "svelte";

  const dispatch = createEventDispatcher();

  export let event;
  let map;
  let mounted = false;
  let venueLayer;
  const initializeMap = async () => {
    // attach map to div, if not already attached
    map = L.map("venuemap", {
      center: [event.geometry.coordinates[1], event.geometry.coordinates[0]],
      zoom: 15,
      scrollWheelZoom: true,
      zoomControl: false,
      attributionControl: false,
      gestureHandling: true,
    });
    let mapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    var cartoUrl = "http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png";
    let attribution = `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`;
    // attach base tile layer to map
    let mapTile = L.tileLayer(mapURL, {
      attribution,
    });
    map.addLayer(mapTile);
    // add marker
    let marker = featureToMarker(event, [
      event.geometry.coordinates[1],
      event.geometry.coordinates[0],
    ]);
    marker.addTo(map).bindTooltip(event.properties.venue.title);
    // add attrbution
    L.control.attribution({ position: "topleft" }).addTo(map);
    return map;
  };
  const iconStyle =
    "height: 26px; width: 26px; background-image: url(/kategorije/16x16px/orange/pin.svg); background-repeat: no-repeat; background-size: contain; background-position: center center; text-align: center; color: white; font-weight: bold; font-size: 14px; font-style: italic; padding-top: 2px;";
  const getIcon = (feature) =>
    L.icon({
      iconUrl: "kategorije/16x16px/orange/pin.svg",
      iconSize: [24, 24], // size of the icon
      iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
      popupAnchor: [24, 0], // point from which the popup should open relative to the iconAnchor
      opacity: 1,
    });
  const featureToMarker = (feature, latlng) => {
    const zIndexOffset = 600;
    let marker = L.marker(latlng);
    marker.setIcon(getIcon(feature));
    marker.setZIndexOffset(zIndexOffset);
    return marker;
  };

  onMount(async () => {
    if (!window.L) await import("leaflet");
    await initializeMap();
  });
</script>

<div class="mapdiv" id="venuemap" />

<style>
  .mapdiv {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
</style>
