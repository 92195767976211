<script>
  import Header from "./Header.svelte";
  import VenueAndContactsInfo from "./VenueAndContactsInfo.svelte";
  import Links from "./Links.svelte";
  import CompetitionsPicker from "../../ui/CompetitionsPicker/present.svelte";
  import EventEditor from "../EventEditor/index.svelte";

  import { createEventDispatcher, setContext } from "svelte";

  // import { checkGrant } from "../../helpers";

  // const dispatch = createEventDispatcher();

  import { user } from "../../stores";
  // export let pageWidth = 1000;
  export let event;
  export let userLocation = {};
  export let dsaFilter = [];
  export let isGrantedFilter = false;
  let isEditModeOn = false;

  const editEvent = () => {
    if (isGrantedFilter) isEditModeOn = true;
  };
  const closeEventEditor = () => {
    isEditModeOn = false;
  };
</script>

<div class="frame">
  {#if event}
    <div class="header" on:dblclick={editEvent}>
      <Header {event} />
    </div>
    {#if isEditModeOn}
      <EventEditor
        bind:event
        on:eventDeleted
        on:closeEventEditorRequested={closeEventEditor}
      />
    {:else}
      <div class="content">
        <!-- <div class="newsbox">
      {#each event.properties.info.crtica as newsItem}{newsItem}{/each}
    </div> -->
        <div class="infobox">
          <VenueAndContactsInfo {event} {userLocation} />
          <Links {event} />
        </div>
        {#if event.properties.program.disciplines.length !== 0}
          <div class="program">
            <CompetitionsPicker
              program={event.properties.program}
              bind:selectedDsaItems={dsaFilter}
            />
          </div>
        {/if}
      </div>
    {/if}
  {/if}
</div>

<style>
  .frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
  }
  .frame .header {
  }
  .frame .warning {
    display: flex;
    color: palevioletred;
  }
  .frame .content {
    display: flex;
    flex-direction: column;
  }
  .frame .content .newsbox {
    margin-top: 10px;
    height: 50px;
  }
  .frame .content .infobox {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid blue; */
  }
  .frame .content .section-title {
    text-align: center;
    margin: 0 5px;
    padding: 8px 10px;
    background-color: lightgray;
    font-size: 16px;
  }
  .frame .content .program {
				border-top: 3px solid lightgray;
			}
</style>
