<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let actions = [];

  const handleClick = actionType => {
    dispatch("crudAction", { actionType });
  };
</script>

<style>
  .frame {
    display: flex;
    justify-content: space-between;
  }
  .frame .icon {
    flex: 0 0 auto;
    height: 30px;
    width: 30px;
    padding: 5px;
    margin: 0 5px;
    border: 1px solid black;
    border-radius: 3px;
  }
  .frame .icon:hover {
    background: lightgray;
  }
  .frame .icon img {
    /* height: 100%; */
    height: 20px;
    object-fit: contain;
  }
  .frame .filler {
    flex: 1 1 auto;
  }
</style>

<div class="frame">
  {#each actions as action}
    {#if action.filler}
      <div class="filler" />
    {/if}
    {#if action.type}
      <div class="icon">
        <button on:click={handleClick.bind(this, action.type)}>
          <img src="icons/{action.type}.png" alt={action.type} />
        </button>
      </div>
    {/if}
  {/each}
</div>
