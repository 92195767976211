<script>
  import VenueMap from "./VenueMap.svelte";

  import { user } from "../../stores";
  export let event;
  export let userLocation;
  
 	const openLink = (linkAddress) => {
		if (linkAddress) window.open(linkAddress);
	};
  
</script>

<style>
  .frame {
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    margin: 0 5px 10px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid navy; */
  }

  .frame .section-title {
    text-align: center;
    margin-bottom: 10px;
    padding: 8px 10px;
    background-color: lightgray;
    font-size: 16px;
    /* border: 1px solid blue; */
  }
  .frame .banner {
    /* flex: 1; */
    position: relative;
    height: 200px;
    max-width: 400px;
  }

  .frame img {
    width: 30px;
    height: 30px;
    padding: 0px;
    margin: 0px;
  }
  
  .frame .item {
			position: absolute;
			bottom: 10px;
			left: 10px;
			height: 39px;
			width: 180px;
			border-radius: 5px;
			background-color: rgba(52, 152, 219, 0.8);
			color: white;
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			z-index: 400;
	}
	
	.frame .item:hover {
		background-color: rgba(52, 152, 219, 1);
	}
		
	.frame .item-icon {
			height: 25px;
			width: 25px;
			margin: 2px 5px;
			object-fit: contain;
		}
		
	.frame	.item-description {
			display: table-cell;
			vertical-align: center;
			margin: 2px 5px;
			font-size: 16px;
			white-space: nowrap;
		}
		
  .frame .contacts-list {
    /* background-color: #ff471a; */
  }

  a {
    color: goldenrod;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    color: #ff471a;
  }
</style>

<div class="frame">
  <div class="banner">
    {#if event.geometry.coordinates[0]}
      <VenueMap {event} />
			<button
				class="item {event.properties.venue && event.properties.venue.address ? 'active' : ''}"
				on:click={() =>
					openLink(`https://www.google.com/maps/search/${event.properties.venue.address}`)}
			>
				<img
					class="item-icon"
					src="icons/navigation{event.properties.venue.address ? '--active' : ''}.png"
					alt="navigate to venue"
				/>
				<div class="item-description">Navigate to Venue</div>
			</button>
    {:else}
      <div style="text-align: center">*** venue not set yet ***</div>
    {/if}
  </div>
  <div class="contacts-list">
    <div class="contact" />
  </div>
</div>
