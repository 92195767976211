<script>
  import { prepend, clone, remove } from "ramda/src";

  import CompetitionsPicker from "../../ui/CompetitionsPicker/index.svelte";

  export let program = {};
  export let eventCategories = [];
  export let defaultCategory = "-";
  let competitionsPickerShown = false;
  const newCompetitionTemplate = {
    setting: "i",
    category: defaultCategory,
    sex: "w",
    age: "abs",
    discipline: null,
    prop: null,
    entry: null,
    note: null
  };
  let newCompetition = {};

  const settings = [
    { id: "i", name: { pp: "eng", eng: "indoor" } },
    { id: "o", name: { pp: "eng", eng: "outdoor" } },
    { id: "x", name: { pp: "eng", eng: "exhibition" } }
  ];

  const toggleCompetitionsPickerShown = () => {
    competitionsPickerShown = !competitionsPickerShown;
  };
  const addCompetition = () => {
    program.competitions = prepend(newCompetition, program.competitions);
    program.dsaArray = prepend(
      `${newCompetition.discipline}-${newCompetition.sex}-${newCompetition.age}`,
      program.dsaArray
    );
    newCompetition = clone(newCompetitionTemplate);
  };
  const addCompetitionUsingPicker = event => {
    newCompetition = { ...newCompetitionTemplate, ...event.detail };
    addCompetition();
  };
  const deleteCompetition = i => {
    let deletedCompetition = program.competitions[i];
    program.dsaArray = program.dsaArray.filter(
      dsaItem =>
        dsaItem !==
        `${deletedCompetition.discipline}-${deletedCompetition.sex}-${deletedCompetition.age}`
    );
    program.competitions = remove(i, 1, program.competitions);
  };
  const rejectCompetitionToggleUsingPicker = event => {
    program.dsaArray = program.dsaArray;
  };

  $: newCompetition.setting = newCompetitionTemplate.setting;
  $: newCompetitionTemplate.category = defaultCategory;
  $: newCompetition.category = newCompetitionTemplate.category;
  $: newCompetition.sex = newCompetitionTemplate.sex;
  $: newCompetition.age = newCompetitionTemplate.age;
</script>

<style>
  .frame {
    border: 1px solid black;
    padding: 2px;
    height: 150px;
    overflow: auto;
  }
  input,
  select {
    background-color: lightsteelblue;
    border: 0;
    font-size: 16px;
    padding: 2px;
  }
  .competition {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    margin-top: 3px;
    padding: 0 2px;
  }
  .competition input {
    margin-left: 4px;
  }
</style>

Presets:
<select bind:value={newCompetitionTemplate.setting}>
  {#each settings as setting}
    <option value={setting.id}>{setting.name[setting.name.pp]}</option>
  {/each}
</select>
<select bind:value={newCompetitionTemplate.category}>
  {#each eventCategories as category}
    <option value={category.name}>{category.name}</option>
  {/each}
</select>
<button on:click={toggleCompetitionsPickerShown}>
  Toggle competitions picker
</button>
<br />
{#if competitionsPickerShown}
  <CompetitionsPicker
    selectedDsaItems={program.dsaArray}
    on:competitionPicked={addCompetitionUsingPicker}
    on:competitionUnpicked={rejectCompetitionToggleUsingPicker}
    on:selectAllCompetitionsToggled={rejectCompetitionToggleUsingPicker} />
{/if}

<!-- <div class="frame"> -->
<p style="text-align: center; font-size: 16px">Competitions list</p>
{#each program.competitions as competition, i}
  <div class="competition">
    <p style="line-height: 25px">
      <button on:click={deleteCompetition.bind(this, i)}>(-)</button>
      <select bind:value={competition.setting}>
        {#each settings as setting}
          <option value={setting.id}>{setting.name[setting.name.pp]}</option>
        {/each}
      </select>
      <select bind:value={competition.category}>
        {#each eventCategories as category}
          <option value={category.name}>{category.name}</option>
        {/each}
      </select>
      <input bind:value={competition.age} placeholder="age" size="1" />
      <input bind:value={competition.sex} placeholder="sex" size="1" />
      <input
        bind:value={competition.discipline}
        placeholder="discipline"
        size="6" />
      <input bind:value={competition.prop} placeholder="prop" size="1" />
      <input bind:value={competition.entry} placeholder="entry" size="1" />
      <input type="datetime-local" bind:value={competition.start} />
      <input bind:value={competition.note} placeholder="note" size="22" />
    </p>
  </div>
{/each}
<!-- </div> -->
