<script>
  import TitleEditor from "../../ui/TranslationsEditor.svelte";
  import AccessEditor from "./AccessEditor.svelte";
  import PlaceEditor from "../../ui/TranslationsEditor.svelte";
  import VenuesBrowser from "../VenuesBrowser/index.svelte";
  import ProgramEditor from "./ProgramEditor.svelte";
  import NotesEditor from "./NotesEditor.svelte";
  import ContactsEditor from "./ContactsEditor.svelte";
  import DocumentsEditor from "./DocumentsEditor.svelte";
  import ActionsBar from "../../ui/ActionsBar/index.svelte";

  import { createEventDispatcher, setContext } from "svelte";

  import { clone } from "ramda/src";

  import { createResource, updateResource, deleteResource } from "../../_api";
  import {
    fetchEventDetailsFromDB,
    extractVenueFromEvent,
    formatDateForDisplay,
    getContactTypesFromDB,
    getEventCategoriesFromDB,
    getEventBrandsFromDB,
    getEventWarrantorsFromDB,
    getEventStatusesFromDB,
  } from "./index";

  import { user } from "../../stores";
  import { actionSet } from "../../ui/ActionsBar";
  import { objectsDiff } from "../../helpers";

  setContext("eventsEditorContext", {
    contactTypes: getContactTypesFromDB(),
  });
  export let event = {};
  // let isEventModified = false;
  let modifiedEvent = {};
  let grantLevel = 0;
  let selectedTab = "general";
  let actions;
  let venuesBrowserShown = false;
  let selectedVenue = {};
  let venueLinked = true;
  let program = [];
  const eventCategories = getEventCategoriesFromDB();
  const eventBrands = getEventBrandsFromDB();
  const eventStatuses = getEventStatusesFromDB();
  const eventWarrantors = getEventWarrantorsFromDB();

  const dispatch = createEventDispatcher();
  const switchTab = (tab) => {
    selectedTab = tab;
  };
  const toggleVenuesBrowser = () => {
    venuesBrowserShown = !venuesBrowserShown;
  };
  const linkVenue = () => {
    modifiedEvent.geometry.coordinates = selectedVenue.geometry.coordinates;
    modifiedEvent.properties.venue = selectedVenue.properties;
    venueLinked = true;
  };
  const unlinkVenue = () => {
    modifiedEvent.properties.venue.id = null;
    modifiedEvent.properties.venue.title = "";
    modifiedEvent.properties.venue.country = "";
    modifiedEvent.properties.venue.place = { pp: "eng", eng: "" };
    modifiedEvent.properties.venue.address = "";
    venueLinked = false;
  };
  const diffEvents = (original, modified) => {
    const prepareEventForObjectsDiff = (event) => ({
      ...event.properties,
      dateForDisplay: "",
      dateRange: "",
      venue: { id: event.properties.venue.id },
      program: { competitions: event.properties.program.competitions },
      whenLastUpdate: "",
      updates: "",
    });
    objectsDiff(
      prepareEventForObjectsDiff(original),
      prepareEventForObjectsDiff(modified)
    );
  };

  const resetEvent = async () => {
    ({ event, grantLevel } = await fetchEventDetailsFromDB($user, event));
    selectedVenue = extractVenueFromEvent(event);
    actions = [actionSet.reset];
    if (grantLevel >= 100) actions.push(actionSet.clone);
    actions.push(actionSet.filler);
    if (grantLevel >= 5) actions.push(actionSet.save);
    // if (grantLevel >= 10) actions.push(actionSet.delete);
    actions.push(actionSet.exit);
    modifiedEvent = clone(event);
    // isEventModified = false;
  };
  const cloneEvent = () => {
    modifiedEvent.properties.id = "newEvent";
    modifiedEvent.properties.updates = [];
    modifiedEvent.properties.title = {
      pp: modifiedEvent.properties.title.pp,
      [modifiedEvent.properties.title.pp]: "(new event)",
    };
    actions = [
      actionSet.reset,
      actionSet.filler,
      actionSet.save,
      actionSet.exit,
    ];
    // isEventModified = true;
  };
  const saveEvent = async () => {
    if (modifiedEvent.properties.id === "newEvent") {
      event = await createResource("event", modifiedEvent, $user.token);
    } else {
      // if (isEventModified || diffEvents(event, modifiedEvent) !== null) {
      event = await updateResource(
        "event",
        modifiedEvent.properties.id,
        modifiedEvent,
        $user.token
      );
      // } else {
      //   alert("No changes made to original event so none saved");
      // }
    }
    resetEvent();
  };
  // const deleteEvent = async () => {
  //   let info = event.properties;
  //   if (
  //     confirm(
  //       `
  //       You requested to delete the following event:
  //       ${info.title[$user.preferedLanguage] || info.title[info.title.pp]} (${
  //         info.date[0]
  //       }, ${info.venue.place} / ${info.venue.country})
  //       Are you sure you want to do it?
  //       `
  //     )
  //   ) {
  //     await deleteResource("event", event.properties.id, $user.token);
  //     dispatch("eventDeleted");
  //   }
  // };
  const exitEventEditor = async () => {
    // if (
    //   diffEvents(event, modifiedEvent) !== null &&
    //   confirm(
    //     `
    //     You did not save the changes you made.
    //     Are you sure you want to exit the editor?
    //     `
    //   )
    // ) {
    ({ event } = await fetchEventDetailsFromDB($user, event));
    dispatch("closeEventEditorRequested");
  };
  // };
  const crudAction = (e) => {
    switch (e.detail.actionType) {
      case "save":
        saveEvent();
        break;
      case "reset":
        resetEvent();
        break;
      case "clone":
        cloneEvent();
        break;
      case "delete":
        deleteEvent();
        break;
      case "exit":
        exitEventEditor();
        break;
      default:
        break;
    }
  };
  const adjustEndDate = () => {
    if (
      modifiedEvent.properties.id !== "newEvent" &&
      modifiedEvent.properties.date[0].substring(0, 4) !==
        event.properties.date[0].substring(0, 4)
    ) {
      alert(`New date must be in the same season. 
      To move the event to new season, first clone it and then set the new date for cloned event.`);
      modifiedEvent.properties.date[0] = event.properties.date[0];
      return;
    }
    modifiedEvent.properties.date[1] = modifiedEvent.properties.date[0];
    // TODO sredi da se ne može mijenjat sezona osim ako je natjecanje klonirano
  };
  const adjustStartDate = () => {
    if (modifiedEvent.properties.date[1] < modifiedEvent.properties.date[0]) {
      // TODO sredi da se ne može mijenjat sezona osim ako je natjecanje klonirano
      adjustEndDate();
      alert(`Event cannot end before it starts!
          Set the end date to correct value.`);
    }
  };

  $: if (selectedVenue && modifiedEvent.properties) linkVenue();

  // $: if (modifiedEvent.properties) {
  //   if (event.properties.title.pp !== modifiedEvent.properties.title.pp)
  //     isEventModified = true;
  //   event.properties.title.pp = modifiedEvent.properties.title.pp;
  // }
  // $: if (modifiedEvent.properties) {
  //   if (
  //     event.properties.title[event.properties.title.pp] !==
  //     modifiedEvent.properties.title[modifiedEvent.properties.title.pp]
  //   )
  //     isEventModified = true;
  //   event.properties.title[event.properties.title.pp] =
  //     modifiedEvent.properties.title[modifiedEvent.properties.title.pp];
  // }
  // $: if (modifiedEvent.properties)
  //   event.properties.dateForDisplay = formatDateForDisplay([
  //     modifiedEvent.properties.date[0],
  //     modifiedEvent.properties.date[1],
  //   ]);
  // $: if (modifiedEvent.properties)
  //   event.properties.venue.country = modifiedEvent.properties.venue.country;
  // $: if (modifiedEvent.properties)
  //   event.properties.venue.place = modifiedEvent.properties.venue.place;
  // $: if (modifiedEvent.properties)
  //   event.properties.program.categories = [modifiedEvent.properties.category];

  resetEvent();
</script>

<div class="frame">
  <!-- {#if isEventModified}
    <div>Event modified</div>
  {/if} -->
  <ActionsBar {actions} on:crudAction={crudAction} />
  <div class="tabs">
    <div
      class="tab"
      class:active-tab={selectedTab === "general"}
      on:click={() => switchTab("general")}
    >
      General
    </div>
    <div
      class="tab"
      class:active-tab={selectedTab === "program"}
      on:click={() => switchTab("program")}
    >
      Program
    </div>
    <div
      class="tab"
      class:active-tab={selectedTab === "contacts"}
      on:click={() => switchTab("contacts")}
    >
      Contacts
    </div>
    <div
      class="tab"
      class:active-tab={selectedTab === "links"}
      on:click={() => switchTab("links")}
    >
      Links
    </div>
    <div
      class="tab"
      class:active-tab={selectedTab === "notes"}
      on:click={() => switchTab("notes")}
    >
      Notes
    </div>
    {#if $user.role === "admin"}
      <div
        class="tab"
        class:active-tab={selectedTab === "grants"}
        on:click={() => switchTab("grants")}
      >
        Grants
      </div>
    {/if}
    {#if $user.role === "admin"}
      <div
        class="tab"
        class:active-tab={selectedTab === "registrations"}
        on:click={() => switchTab("registrations")}
      >
        Registrations
      </div>
    {/if}
  </div>
  {#if modifiedEvent.properties}
    {#if selectedTab === "general"}
      <div class="content">
        <div class="section-title">Event Title</div>
        <TitleEditor bind:item={modifiedEvent.properties.title} />
        Event slug:
        <input bind:value={modifiedEvent.properties.slug} size="30" />
        <div class="section-title">Meeting Series</div>
        <select bind:value={modifiedEvent.properties.brand}>
          {#each eventBrands as brand}
            <option value={brand.id}>{brand[brand.pp]} ({brand.owner})</option>
          {/each}
        </select>
        <hr />
        <div class="section-title">Dates</div>
        <p>
          Starts:
          <input
            type="date"
            bind:value={modifiedEvent.properties.date[0]}
            on:blur={adjustEndDate}
          />
        </p>
        <p>
          Ends:
          <input
            type="date"
            bind:value={modifiedEvent.properties.date[1]}
            on:blur={adjustStartDate}
          />
        </p>
        <div class="section-title">Venue</div>
        Id:
        <input bind:value={modifiedEvent.properties.venue.id} disabled />
        <br />
        Country:
        <input bind:value={modifiedEvent.properties.venue.country} />
        <br />
        Place:
        <PlaceEditor bind:item={modifiedEvent.properties.venue.place} />
        Title:
        <input bind:value={modifiedEvent.properties.venue.title} disabled />
        <br />
        Address:
        <input bind:value={modifiedEvent.properties.venue.address} disabled />
        <br />
        <button on:click={toggleVenuesBrowser}>(--BROWSE--)</button>
        <button on:click={unlinkVenue}>(--CLEAR--)</button>
        {#if venuesBrowserShown}
          <VenuesBrowser bind:selectedVenue />
        {/if}
        <div class="section-title">Admin</div>
        Source:
        <input
          type="text"
          length="3"
          bind:value={modifiedEvent.properties.source}
        />
        <br />
        Warrantor:
        <select bind:value={modifiedEvent.properties.warrantor}>
          {#each eventWarrantors as warrantor}
            <option value={warrantor.id}>{warrantor.eng}</option>
          {/each}
        </select>
        <br />
        Status:
        <select bind:value={modifiedEvent.properties.status}>
          {#each eventStatuses as status}
            <option value={status.id.toString()}>{status.eng}</option>
          {/each}
        </select>
        <br />
        <label>
          <input
            type="checkbox"
            bind:checked={modifiedEvent.properties.isPublished}
          />
        </label>
        Publish Event
      </div>
    {/if}
    {#if selectedTab === "program"}
      <div class="content">
        <div class="section-title">WA Classification</div>
        <select bind:value={modifiedEvent.properties.category}>
          {#each eventCategories as category}
            <option value={category.name}>
              {category.name}
              ({category.description})
            </option>
          {/each}
        </select>
        <div class="section-title">Interntational Access</div>
        <AccessEditor bind:access={modifiedEvent.properties.access} />
        <div class="section-title">Program Editor</div>
        <ProgramEditor
          bind:program={modifiedEvent.properties.program}
          bind:competitionsList={modifiedEvent.properties.competitionsList}
          {eventCategories}
          defaultCategory={modifiedEvent.properties.category}
        />
      </div>
    {/if}
    {#if selectedTab === "contacts"}
      <div class="content">
        <ContactsEditor
          {event}
          bind:contacts={modifiedEvent.properties.contacts}
        />
      </div>
    {/if}
    {#if selectedTab === "links"}
      <div class="content">
        <DocumentsEditor bind:links={modifiedEvent.properties.links} />
      </div>
    {/if}
    {#if selectedTab === "notes"}
      <div class="content">
        <NotesEditor bind:notes={modifiedEvent.properties.notes} />
      </div>
    {/if}
    {#if selectedTab === "grants"}
      <div class="content">tu uređujem grantove</div>
    {/if}
    {#if selectedTab === "registrations"}
      <div class="content">tu uređujem prijave natjecatelja</div>
    {/if}
  {/if}
</div>

<style>
  .frame {
    padding: 5px;
    background-color: white;
    max-width: calc(100vw - 20px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    /* border: 2px solid gold; */
  }
  .tabs .active-tab {
    color: #3498db;
    background: white;
    border: 1px solid #3498db;
  }
  .tab {
    height: 30px;
    line-height: 15px;
    flex: 1 1 auto;
    min-width: 90px;
    text-align: center;
    font-size: 18px;
    margin: 2px;
    padding: 5px;
    background: #3498db;
    color: white;
    cursor: pointer;
  }
  .tab:hover {
    font-weight: bolder;
  }
  .content {
  }
  .frame .content .section-title {
    text-align: center;
    margin: 2px;
    padding: 4px 10px;
    background-color: lightgray;
    font-size: 16px;
    /* border: 1px solid blue; */
  }
  input {
    background-color: lightsteelblue;
  }
  p {
    display: inline-block;
    text-align: left;
  }
</style>
