<script>
  export let links;
</script>

<style>
  .frame {
    display: flex;
    flex-wrap: wrap;
  }

  .frame .link-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin: 5px 2px 1px;
    border-bottom: 1px solid darkslategrey;
  }
  .frame .link-item .description {
    white-space: nowrap;
    font-size: 16px;
    font-weight: bolder;
    margin-right: 10px;
    flex: 0 0 auto;
  }
  .frame .link-item .link {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    max-width: 75%;
    /* border: 1px solid black; */
  }
  .frame .link-item .link input {
    font-size: 16px;
    width: 100%;
    background-color: lightsteelblue;
    padding: 2px;
  }
</style>

<div class="frame">
  <div class="link-item">
    <div class="description">
      <a href={links.source} target="_blank">Referenced source</a>
    </div>
    <div class="link">
      <input bind:value={links.source} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.website} target="_blank">Official Website</a>
    </div>
    <div class="link">
      <input bind:value={links.website} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.facebook} target="_blank">Facebook Profile</a>
    </div>
    <div class="link">
      <input bind:value={links.facebook} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.invitation} target="_blank">Invitation / Program</a>
    </div>
    <div class="link">
      <input bind:value={links.invitation} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.startlist} target="_blank">Start List</a>
    </div>
    <div class="link">
      <input bind:value={links.startlist} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.results} target="_blank">Results</a>
    </div>
    <div class="link">
      <input bind:value={links.results} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.stream} target="_blank">Real-Time Video Stream</a>
    </div>
    <div class="link">
      <input bind:value={links.stream} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.video} target="_blank">Video Channel</a>
    </div>
    <div class="link">
      <input bind:value={links.video} />
    </div>
  </div>

  <div class="link-item">
    <div class="description">
      <a href={links.gallery} target="_blank">Photo Gallery</a>
    </div>
    <div class="link">
      <input bind:value={links.gallery} />
    </div>
  </div>
</div>
