<script>
  import { prepend, clone, remove } from "ramda";
  import { format } from "date-fns";

  import { user } from "../../stores";
  export let notes;
  let newNote = {};
  let nullNote = {
    isPublic: false,
    content: "",
  };

  const addNote = () => {
    let now = format(new Date(), "yyyy-MM-dd HH:mm");
    newNote.created = now;
    newNote.author = {
      userame: $user.userName,
      id: $user.id,
    };
    notes = R.prepend(newNote, notes);
    newNote = R.clone(nullNote);
  };
  const deleteNote = (i) => {
    notes = R.remove(i, 1, notes);
  };
</script>

<!-- <div class="biljeske"> -->
<h5>(New note)</h5>
<h5>
  (Make public
  <input class="newNote" type="checkbox" bind:checked={newNote.isPublic} />
  | Add
  <button on:click={addNote}>(--ADD--)</button>
</h5>
)
<br />
<textarea class="newNote" rows="2" bind:value={newNote.content} />
<hr />
{#each notes as note, i}
  {#if ($user.role = "admin" || note.isPublic || note.author.id === $user.id)}
    <h5>Posted <b>{note.created}</b> by <b>{note.author.username}</b></h5>
    {#if note.author.id === $user.id}
      <h5>
        (Make public
        <input type="checkbox" bind:checked={note.isPublic} />
        | Delete
        <button on:click={deleteNote.bind(this, i)}>(--REMOVE--)</button>
      </h5>
      )
      <textarea rows="2" bind:value={note.content} />
    {:else}
      <div>{note.content}</div>
    {/if}
    <hr />
  {/if}
{/each}

<!-- </div> -->
<style>
  .biljeske {
    border: 1px solid black;
    padding: 4px;
    height: 150px;
    overflow: scroll;
  }
  h5 {
    /* margin-top: 2px;
    margin-bottom: 2px; */
    display: inline;
  }
  .newNote {
  }
  textarea {
    padding: 2px;
    background-color: lightsteelblue;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    width: 100%;
  }
</style>
