<script>
  import { append } from "ramda/src";

  import { filterCountriesList } from "./AccessEditor";

  import { user } from "../../stores";
  import { countriesList } from "./AccessEditor";
  /* *** */
  const tempCountriesList = countriesList[3]["countries"];
  /* *** */

  export let access = [];
  let filteredCountriesList = [];
  let countryFilter = "";

  const toggleAccessGranted = (country) => {
    access = access.includes(country.id)
      ? access.filter((id) => id !== country.id)
      : append(country.id, access);
  };

  $: if (access.length === 0) access = append("any", access);
  $: {
    countryFilter;
    filteredCountriesList = filterCountriesList(
      tempCountriesList,
      countryFilter
    );
  }
</script>

<style>
  .znacke {
    display: flex;
    flex-wrap: wrap;
  }
  /* .znacka {
    --bgd: darkblue;
    margin: 2px;
    font-size: 10px;
    font-weight: bold;
    vertical-align: middle;
    color: white;
    background-color: var(--bgd);
    opacity: 0.6;
    border-radius: 5px;
    cursor: pointer;
    padding: 2px;
    user-select: none;
  } */
  /* .attributed {
    opacity: 1;
    color: gold;
  } */
  .znacka {
    height: var(--flag-height);
    width: var(--flag-width);
    overflow: hidden;
    box-shadow: 0 0 5px 5px var(--color-backg-mid);
    opacity: 0.5;
    cursor: pointer;
  }
  .znacka img {
    height: var(--flag-height);
    width: var(--flag-width);
    object-fit: contain;
  }
  input {
    border: 1px solid black;
  }
  .selected {
    opacity: 1;
    background: #f58d20;
  }
</style>

Filter countries by name:
<input bind:value={countryFilter} />
<br />
<div class="znacke">
  {#each filteredCountriesList as country}
    <div class="znacka" class:selected={access.includes(country.id)}>
      <img
        on:click={() => toggleAccessGranted(country)}
        src="flags/svg/{country.id}.svg"
        title={country.name[$user.preferedLanguage] || country.name[country.name.pp]} />
    </div>
  {/each}
</div>
