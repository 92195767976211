<script>
  export let event;
</script>

<div class="frame">
  <div class="event-title">
    {event.properties.title[event.properties.title.pp]}
  </div>
  <div class="basic-info">
    <div class="info-item">
      <img class="icon" src="icons/date.png" alt="" />
      <div class="text">{event.properties.dateForDisplay}</div>
    </div>
    <div class="info-item">
      <img class="icon" src="icons/location.png" alt="" />
      <img
        class="icon"
        src="flags/svg/{event.properties.venue.country}.svg"
        alt=""
      />
      <div class="text">
        {event.properties.venue.place[event.properties.venue.place.pp]}
      </div>
    </div>
    <div class="info-item">
      <img class="icon" src="icons/level.png" alt="" />
      {#each event.properties.program.categories as category}
        <div class="category">{category.toUpperCase()}</div>
      {/each}
    </div>
  </div>
</div>

<style>
  .frame {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: lightgray;
    padding: 5px 0;
  }
  .frame .event-title {
    flex: 1 1 auto;
    padding: 5px 10px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .frame .basic-info {
    flex: 0 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    /* height: 48px;
    padding: 1vh 0 1vh 3vw; */
  }
  .frame .basic-info .info-item {
    flex: 0;
    padding: 5px 10px 5px 0;
    display: flex;
    align-items: center;
    /* border: 1px solid navy; */
  }
  .frame .basic-info .info-item .icon {
    height: 30px;
    width: 30px;
    margin: 0 0 0 10px;
    object-fit: contain;
    /* border: 1px solid blue; */
  }
  .category {
    height: 26px;
    width: 26px;
    margin-left: 5px;
    background-image: url(/kategorije/16x16px/orange/pin.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    /* box-shadow: 0 0 5px 5px var(--color-backg-mid); */

    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
    padding-top: 4px;
  }
  .frame .basic-info .info-item .text {
    margin-left: 10px;
    font-size: 18px;
    white-space: nowrap;
  }
</style>
