<script>
  import { append, clone, remove } from "ramda/src";

  import { getContext } from "svelte";

  import { v4 } from "uuid";
  import { format } from "date-fns";

  const { contactTypes } = getContext("eventsEditorContext");

  export let event;
  export let contacts = [];
  let newContact = {};
  const nullContact = {
    value: "",
    about: "",
  };

  const addContact = () => {
    contacts = append(newContact, contacts);
    newContact = clone(nullContact);
  };
  const deleteContact = (i) => {
    contacts = remove(i, 1, contacts);
    newContact = clone(nullContact);
  };

  // !!!*** privremeno, za mailanje, ni slučajno na produkciju ***!!!
  const initiateNegotiation = async () => {
    const url = "http://127.0.0.1:3030/api/v2/negotiations";

    const negotiation = {
      id: null,
      idAthrep: "8f2ea1dc-4820-406c-9ac7-9803801ee88f",
      idEvent: event.properties.id,
      status: "i",
      isArchived: false,
      whenLastUpdated: undefined,
      details: {
        about: `${
          event.properties.date[0]
        } - ${eventLocation}, ${event.properties.venue.country.toUpperCase()} - ${event.properties.category.toUpperCase()}`,
        eventDate: event.properties.date,
        athlia: {
          name: athliaName,
          email: athliaEmail,
        },
        athletes: "",
        hospitality: {
          travel: "",
          accommodation: "",
          prizes: "",
          targetResult: null,
        },
        notes: [
          {
            id: v4(),
            when: format(new Date(), "yyyy-MM-dd hh:mm"),
            athletes: [],
            content: `Sent email inquiry to ${athliaName} (${athliaEmail})`,
          },
        ],
        todos: [],
      },
    };
    const body = JSON.stringify(negotiation);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
  };

  let athliaEmail;
  let mailBody;
  let eventTitle;
  let eventLocation;
  let eventLink;

  $: eventTitle = event?.properties?.title[event?.properties?.title?.pp];
  $: eventDate = event?.properties.dateForDisplay;
  $: eventLocation =
    event?.properties?.venue?.place[event?.properties?.venue?.place?.pp];
  $: eventLink = `https://trackmeetings.info/event/${event?.properties?.id}`;
  $: mailSubject = encodeURIComponent(
    `${eventTitle} - participation of foreign athletes?`
  );
  $: athliaEmail = event?.properties?.contacts?.findLast(
    (contact) => contact?.type === "email"
  )?.value;
  $: athliaName = event?.properties?.contacts?.findLast(
    (contact) => contact?.type === "email"
  )?.about;
  let mailCc = "duje@trackmeetings.info";

  $: mailBody = encodeURIComponent(`Dear mr. ms. ${athliaName},\n
  I am Duje Bonacci, WA licensed athletes' representative from Croatia.\n
  Could you please tell me if ${eventTitle}, to be held on ${eventDate} in ${eventLocation} (${eventLink}) is open for participation to foreign athletes and if so, do you offer any prize fund and/or provide travel/accommodation expenses reimbursement?\n
  Best regards,\n
  Duje\n
  `);
</script>

<div class="frame">
  {#if athliaEmail}
    <div on:click={initiateNegotiation}>
      <a
        href="mailto:{athliaEmail}?cc={mailCc}&subject={mailSubject}&body={mailBody}"
        >Request info</a
      >
    </div>
  {/if}
  <div class="contact">
    <button on:click={addContact}>(add)</button>
    type:
    <select bind:value={newContact.type}>
      {#each contactTypes as type}
        <option value={type}>{type}</option>
      {/each}
    </select>
    value:
    <input class="newContact" bind:value={newContact.value} size="10" />
    about:
    <input class="newContact" bind:value={newContact.about} size="10" />
    is public:
    <input type="checkbox" bind:checked={newContact.isPublic} />
  </div>
  {#each contacts as contact, i}
    <div class="contact">
      <button on:click={deleteContact.bind(this, i)}>(remove)</button>
      type:
      <select bind:value={contact.type}>
        {#each contactTypes as type}
          <option value={type}>{type}</option>
        {/each}
      </select>
      value:
      <input class="existingContact" bind:value={contact.value} size="10" />
      about:
      <input class="existingContact" bind:value={contact.about} size="10" />
      is public:
      <input type="checkbox" bind:checked={contact.isPublic} />
    </div>
  {/each}
</div>

<style>
  .frame {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .frame .contact {
    width: 100%;
    flex: 0 0 auto;
    margin-top: 3px;
    margin-right: 5px;
  }
  .existingContact {
    background-color: lightsteelblue;
    border: 1px solid black;
    border: 0;
  }
  .newContact {
    background-color: lightgoldenrodyellow;
    border: 1px solid black;
    border: 0;
  }
  input,
  select {
    background-color: lightsteelblue;
    border: 0;
    font-size: 16px;
    padding: 2px;
  }
</style>
