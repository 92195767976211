<script>
  import { createEventDispatcher } from "svelte";

  import { append, clone } from "ramda/src";
  import {
    getAllPossibleDsaItems,
    getAllPossibleDisciplines,
    getAllPossibleDisciplineGroups,
    getAllPossibleSexes,
    getAllPossibleAges,
  } from "./index";

  import { disciplinesGroups, sexes, ages } from "./index";
  export let program;
  export let selectedDsaItems = [];
  let availableDsaItems = program.dsaArray;
  let cdsa = program.cdsa;
  let disciplineGroupFilter = getAllPossibleDisciplineGroups();
  let availableDisciplines = program.disciplines;
  let availableDisciplinesByCategory = program.disciplinesByCategory;
  let availableSexes = program.sexes;
  let sexesFilter = clone(availableSexes);
  let availableAges = program.ages;
  let agesFilter = program.ages;
  let availableCategories = program.categories;
  let categoriesFilter = availableCategories;
  let vacancies = program.vacancies;
  let allSelected = false;

  const dispatch = createEventDispatcher();
  const toggleCompetitionFiltered = (competition) => {
    const dsaItem = `${competition.discipline}-${competition.sex}-${competition.age}`;
    if (selectedDsaItems.includes(dsaItem)) {
      selectedDsaItems = selectedDsaItems.filter((item) => item !== dsaItem);
      dispatch("competitionUnpicked", competition);
    } else {
      selectedDsaItems = append(dsaItem, selectedDsaItems);
      dispatch("competitionPicked", competition);
    }
  };
  const toggleSelectAllCompetitions = () => {
    selectedDsaItems = selectedDsaItems.length > 0 ? [] : availableDsaItems;
    allSelected = !allSelected;
    dispatch("selectAllCompetitionsToggled");
  };
  const isDsaListed = (competition) => availableDsaItems.includes(competition);

  const toggleSexSelected = (id) => {
    if (availableSexes.includes(id))
      sexesFilter = sexesFilter.includes(id)
        ? sexesFilter.filter((sexId) => sexId !== id)
        : append(id, sexesFilter);
  };

  const toggleAgeSelected = (id) => {
    if (availableAges.includes(id))
      agesFilter = agesFilter.includes(id)
        ? agesFilter.filter((ageId) => ageId !== id)
        : append(id, agesFilter);
  };

  const toggleDisciplineGroupSelected = (id) => {
    disciplineGroupFilter = disciplineGroupFilter.includes(id)
      ? disciplineGroupFilter.filter((dgId) => dgId !== id)
      : append(id, disciplineGroupFilter);
  };

  $: {
    availableDisciplines = program.disciplines;
    availableDisciplinesByCategory = program.disciplinesByCategory;
    availableSexes = program.sexes;
    availableAges = program.ages;
    availableDsaItems = program.dsaArray;
    cdsa = program.cdsa;
    vacancies = program.vacancies;
  }
  
  $: console.log(vacancies)
</script>

<div class="frame">
  <div class="sex-age-filters">
    <div class="sex-filters">
      <!-- <div
        class="znacka age"
        class:isSelected={allSelected}
        on:click={toggleSelectAllCompetitions}>
        <img src="icons/select-all.png" alt="" />
      </div> -->
      {#each sexes as sex}
        <div
          class="znacka {availableSexes.includes(sex.id) ? sex.id : ''}"
          class:isUnavailable={!availableSexes.includes(sex.id)}
          class:isSelected={sexesFilter.includes(sex.id)}
          on:click={() => toggleSexSelected(sex.id)}
        >
          <img src="icons/{sex.id}.png" alt="" />
        </div>
      {/each}
    </div>
    <div class="age-filters">
      {#each ages as age}
        <div
          class="znacka"
          class:age={availableAges.includes(age.id)}
          class:isUnavailable={!availableAges.includes(age.id)}
          class:isSelected={agesFilter.includes(age.id)}
          on:click={() => toggleAgeSelected(age.id)}
        >
          {age.id.toUpperCase()}
        </div>
      {/each}
    </div>
  </div>
  <div class="competitions-frame">
    {#if cdsa && availableDisciplinesByCategory}
      {#each availableCategories as category}
        <div class="category-header">
          WA rank: {category.toUpperCase()}
        </div>
        {#each disciplinesGroups as group}
          <div class="competitions-group">
            <div class="group-header">
              <div class="group-name">{group.name[group.name.pp]}</div>
              <img
                src="icons/{!disciplineGroupFilter.includes(group.id)
                  ? 'maximize'
                  : 'minimize'}.png"
                alt=""
                on:click={() => toggleDisciplineGroupSelected(group.id)}
              />
            </div>
            {#if disciplineGroupFilter.includes(group.id)}
              <div class="group-disciplines">
                {#each group.disciplines as discipline}
                  {#if availableDisciplinesByCategory[category].includes(discipline.id)}
                    <div class="discipline">
                      <div class="discipline-name">{discipline.name.eng}</div>
                      {#each sexesFilter as sex}
                        <div class="discipline-competitions">
                          {#each agesFilter as age}
                            {#if program.cdsa[category].includes(`${discipline.id}-${sex}-${age}`)}
                              <div
                                on:click={() =>
                                  toggleCompetitionFiltered({
                                    sex,
                                    age,
                                    discipline: discipline.id,
                                  })}
                                class="znacka {sex}"
                                class:isSelected={selectedDsaItems.includes(
                                  `${discipline.id}-${sex}-${age}`
                                )}
                              >
                                {age.toUpperCase()}
                                {#if vacancies && vacancies[category] && vacancies[category][`${discipline.id}-${sex}-${age}`]}
                                  <span
                                    class="swatch {vacancies[category][`${discipline.id}-${sex}-${age}`].vacancyAlert
                                      ? 'alert'
                                      : ''}"
                                    >{vacancies[category][`${discipline.id}-${sex}-${age}`].vacantLanes}</span
                                  >
                                {/if}                                
                              </div>
                            {/if}
                          {/each}
                        </div>
                      {/each}
                    </div>
                  {/if}
                {/each}
              </div>
            {/if}
          </div>
        {/each}
      {/each}
    {:else}
      {#each disciplinesGroups as group}
        <div class="competitions-group">
          <div class="group-header">
            <div class="group-name">{group.name[group.name.pp]}</div>
            <img
              src="icons/{!disciplineGroupFilter.includes(group.id)
                ? 'maximize'
                : 'minimize'}.png"
              alt=""
              on:click={() => toggleDisciplineGroupSelected(group.id)}
            />
          </div>
          {#if disciplineGroupFilter.includes(group.id)}
            <div class="group-disciplines">
              {#each group.disciplines as discipline}
                {#if availableDisciplines.includes(discipline.id)}
                  <div class="discipline">
                    <div class="discipline-name">{discipline.name.eng}</div>
                    {#each sexesFilter as sex}
                      <div class="discipline-competitions">
                        {#each agesFilter as age}
                          {#if isDsaListed(`${discipline.id}-${sex}-${age}`)}
                            <div
                              on:click={() =>
                                toggleCompetitionFiltered({
                                  sex,
                                  age,
                                  discipline: discipline.id,
                                })}
                              class="znacka {sex}"
                              class:isSelected={selectedDsaItems.includes(
                                `${discipline.id}-${sex}-${age}`
                              )}
                            >
                              {age.toUpperCase()}
                            </div>
                          {/if}
                        {/each}
                      </div>
                    {/each}
                  </div>
                {/if}
              {/each}
            </div>
          {/if}
        </div>
      {/each}
    {/if}
  </div>
</div>

<style>
  .frame {
  }
  .sex-age-filters {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    /* background-color: lightgray; */
    justify-content: space-between;
    align-items: center;
  }
  .sex-filters {
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-start;
    margin: 5px 0;
    /* background-color: #ecf0f1; */
  }
  .age-filters {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
    /* background-color: #ecf0f1; */
  }
  .competitions-frame {
    /* width: calc(100% + 10px); */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin: -5px; */
  }
  .category-header {
    width: 100%;
    background: #f58d20;
    color: whitesmoke;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    margin: 0 5px;
    text-align: right;
  }
  .competitions-group {
    flex: 1 1 auto;
    border: 1px solid rgb(59, 211, 238);
    border-radius: 3px;
    margin: 5px;
    padding: 5px;
  }
  .group-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #3498db;
    color: white;
  }
  .group-header img {
    height: 20px;
    width: 20px;
    cursor: pointer;
    padding: 5px;
  }
  .group-name {
    height: 30px;
    font-size: 16px;
    font-weight: bolder;
    padding: 5px 10px;
  }
  .group-disciplines {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .discipline {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px;
    background-color: #ecf0f1;
    border-radius: 3px;
    justify-content: flex-start;
  }
  .discipline-name {
    font-size: 14px;
    padding: 5px;
    text-align: left;
  }
  .discipline-competitions {
    /* width: 70%; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
  }
  .znacke {
    display: flex;
    flex: 0 0 auto;
  }
  .znacka {
    position: relative;
    flex: 0 1 auto;
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    margin: 2px;
    padding: 5px 2px;
    border-radius: 5px;
    user-select: none;
  }
    .znacka .swatch {
    position: absolute; /* Positioned relative to the button */
    width: 18px;
    height: 18px;
    border-radius: 50%; /* Makes it circular */
    border: 1px solid white;
    background-color: gold; /* Swatch color */
    top: -12px; /* Moves the swatch above the button */
    right: -6px; /* Moves the swatch to the right of the button */
    text-align: center;
    line-height: 16px; /* Vertically centers text (matches height of swatch) */
    font-weight: bold;
    font-size: 12px;
    color: black;
  }
  .znacka .alert {
    background-color: red;
    color: white;
  }
  .isUnavailable {
    background-color: white;
    color: black;
    border: 2px solid white;
    cursor: initial;
  }
  .isSelected {
    opacity: 1;
  }
  .age {
    background-color: gray;
    color: white;
    border: 2px solid gray;
  }
  .w {
    background-color: rgb(235, 92, 92);
    color: white;
    border: 2px solid rgb(235, 92, 92);
  }
  .m {
    background-color: rgb(82, 137, 209);
    color: white;
    border: 2px solid rgb(82, 137, 209);
  }
  .x {
    background-color: goldenrod;
    color: black;
    border: 2px solid goldenrod;
  }
  .znacka img {
    height: 23px;
    padding: 0 8px;
  }

  .test {
    width: 500px;
    height: 500px;
    background-color: gray;
    border: 2px solid turquoise;
  }
</style>
